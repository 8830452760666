<template>
  <div class="navbar-container d-flex content align-items-center">
    <div class="d-none d-lg-flex d-md-block">
      <!-- <b-link to="/"> -->
      <img
        src="/img/lot-logo.png"
        alt="logo"
        class="logo"
        height="45"
      >
      <!-- </b-link> -->
    </div>

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none d-md-none">
      <img
        src="/img/lot-logo.png"
        alt="logo"
        class="logo"
        height="40px"
      >
    </ul>

    <!-- Left Col -->
    <!-- <div
      class="
        bookmark-wrapper
        align-items-center
        flex-grow-1
        d-xl-none d-lg-none
        text-center
      "
    >

      <img
        src="/img/logo.png"
        alt="logo"
        class="logo"
        height="35"
      >

    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav
        align="right"
        class="text-white d-none d-lg-flex align-items-center"
      >
        <b-nav-item
          v-if="role !== 'agent'"
          :class="{ active: $route.name === 'home' }"
          class="mr-1"
          :to="{ name: 'home' }"
        >
          หน้าหลัก
        </b-nav-item>
        <b-nav-item
          v-if="role !== 'agent'"
          :class="{ active: $route.name === 'buy-lottery' }"
          class="mr-1"
          :to="{ name: 'buy-lottery' }"
        >
          ซื้อหวย
        </b-nav-item>
        <b-nav-item
          v-if="role === 'agent'"
          :class="{ active: $route.name === 'agent-buy-lottery' }"
          class="mr-1"
          :to="{ name: 'agent-buy-lottery' }"
        >
          ซื้อหวยเอเย่นต์
        </b-nav-item>

        <b-nav-item
          v-if="!role"
          @click="modalLogin()"
        >
          <span class="btn btn-warning cs pusle">
            <i class="fas fa-sign-in-alt" /> เข้าสู่ระบบ
          </span>
        </b-nav-item>

        <b-nav-item
          v-if="!role"
          class="mr-1"
          @click="modalRegister()"
        >
          <span class="btn btn-primary cg pusle">
            <i class="fas fa-user-plus" /> สมัคร
          </span>
        </b-nav-item>

      </b-nav>
      <!-- <span
        v-if="role"
        class="badge badge-primary m-0"
      ><i class="fas fa-wallet" /> {{ balance ? Commas(balance) : 0.00 }}</span> -->
      <b-nav-item-dropdown
        v-if="role"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <!-- <div class="d-sm-flex user-nav">
            <span class="badge badge-primary"><i class="fas fa-wallet" /> {{ balance ? Commas(balance) : 0.00 }}</span>
          </div> -->
          <b-avatar
            size="40"
            variant="primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          >
            <i class="fas fa-user-circle fa-2x" />
          </b-avatar>
        </template>

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'profile' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
            style="color: #5e5873"
          />
          <span>ข้อมูลของฉัน</span>
        </b-dropdown-item> -->

        <b-dropdown-item
          v-if="role === 'agent'"
          link-class="d-flex align-items-center"
          :to="{ name: 'cart-agent' }"
        >
          <feather-icon
            size="16"
            icon="ShoppingCartIcon"
            class="mr-50"
            style="color: #5e5873"
          />
          <span>คำสั่งซื้อ</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-else
          link-class="d-flex align-items-center"
          :to="{ name: 'cart' }"
        >
          <feather-icon
            size="16"
            icon="ShoppingCartIcon"
            class="mr-50"
            style="color: #5e5873"
          />
          <span>คำสั่งซื้อ</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="role === 'agent'"
          link-class="d-flex align-items-center"
          :to="{ name: 'lotto-list-agent' }"
        >
          <feather-icon
            size="16"
            icon="ShoppingBagIcon"
            class="mr-50"
            style="color: #5e5873"
          />
          <span>รายการสลากฯ</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-else
          link-class="d-flex align-items-center"
          :to="{ name: 'lotto-list' }"
        >
          <feather-icon
            size="16"
            icon="ShoppingBagIcon"
            class="mr-50"
            style="color: #5e5873"
          />
          <span>รายการสลากฯ</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'home' }"
        >
          <feather-icon
            size="16"
            icon="BookmarkIcon"
            class="mr-50"
            style="color: #5e5873"
          />
          <span>ผลรางวัล</span>
        </b-dropdown-item>

        <!-- <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            style="color: #5e5873"
            class="mr-50"
          />
          <span>ออกจากระบบ</span>
        </b-dropdown-item> -->
      </b-nav-item-dropdown>

      <!-- <img
        v-if="role"
        src="https://beta.mekonglottery.co/assets/images/flags/th.png"
        alt="flag-th"
        class="flag-icon"
        height="25"
      > -->

      <feather-icon
        v-if="role"
        v-b-toggle.sidebar-footer
        icon="MenuIcon"
        class="cursor-pointer"
        size="25"
      />

      <div
        v-if="!role"
        class="d-flex align-items-center d-lg-none d-md-none"
      >
        <span
          class="btn btn-primary cs mobile pusle"
          style="margin-right: 5px;"
          @click="modalLogin()"
        >
          <!-- <i class="fas fa-sign-in-alt" /> -->
          เข้าสู่ระบบ
        </span>

        <span
          class="btn btn-primary cg mobile pusle"
          @click="modalRegister()"
        >
          <!-- <i class="fas fa-user-plus" />  -->
          สมัคร
        </span>
      </div>
    </b-navbar-nav>

    <!-- Modal Login -->
    <b-modal
      id="modal-login"
      ref="modal-login"
      size="lg"
      hide-footer
      no-close-on-backdrop
      title="เข้าสู่ระบบ"
      centered
    >
      <validation-observer ref="simpleRules">
        <div>
          <label for="tel">เบอร์โทรศัพท์</label>
          <validation-provider
            #default="{ errors }"
            name="Phone number"
            rules="required"
          >
            <b-form-input
              id="tel"
              v-model="email"
              :state="errors.length > 0 ? false : null"
              type="tel"
              size="lg"
            />
            <small
              class="text-danger"
              style="font-size: 14px"
            >{{
              errors[0] ? "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง" : ""
            }}</small>
          </validation-provider>
        </div>

        <div class="mt-2">
          <label for="password">รหัสผ่าน</label>
          <validation-provider
            #default="{ errors }"
            name="Password"
            rules="required"
          >
            <b-form-input
              id="password"
              v-model="password"
              :state="errors.length > 0 ? false : null"
              type="password"
              size="lg"
            />
            <small
              class="text-danger"
              style="font-size: 14px"
            >{{
              errors[0] ? "กรุณากรอกรหัสผ่านให้ถูกต้อง" : ""
            }}</small>
          </validation-provider>
        </div>

        <div class="mt-2">
          <b-row>
            <b-col md="6">
              <b-button
                variant="warning"
                size="lg"
                block
                class="mb-1 cs"
                @click="login()"
              ><i class="fas fa-sign-in-alt" /> เข้าสู่ระบบ</b-button>
            </b-col>
            <b-col md="6">
              <b-button
                variant="success"
                size="lg"
                class="mb-1 cg"
                block
                @click="modalRegister()"
              >
                <i class="fas fa-user-plus" /> สมัครสมาชิก
              </b-button>
            </b-col>
            <!-- <b-col md="4">
              <b-button
                variant="warning"
                size="lg"
                class="mb-1 cr"
                block
              >
                ลืมรหัสผ่าน ?
              </b-button>
            </b-col> -->
          </b-row>
          <!-- <hr> -->
          <!-- <b-row>
            <b-col md="6">
              <b-button
                variant="success"
                size="lg"
                class="btn-line mt-1"
                block
              ><i class="fab fa-line" /> เข้าสู่ระบบด้วย LINE</b-button>
            </b-col>
            <b-col md="6">
              <b-button
                variant="primary"
                class="btn-facebook mt-1"
                size="lg"
                block
              ><i class="fab fa-facebook-square" /> เข้าสู่ระบบด้วย
                Facebook</b-button>
            </b-col>
          </b-row> -->
        </div>
      </validation-observer>
    </b-modal>
    <!-- End Modal Register and Login -->

    <!-- sidebar -->
    <b-sidebar
      id="sidebar-footer"
      aria-label="Sidebar with custom footer"
      shadow
      backdrop
      right
      bg-variant="white"
    >
      <div class="text-center">
        <img
          src="/img/lot-logo.png"
          alt="logo"
          class="logo"
          height="40px"
        >
      </div>

      <b-list-group class="mt-3 rounded-0">
        <b-list-group-item
          v-if="role !== 'agent'"
          class="bg-transparent text-white"
          :to="{ name: 'buy-lottery' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>ซื้อหวย</span>
        </b-list-group-item>

        <b-list-group-item
          v-if="role === 'agent'"
          class="bg-transparent text-white"
          :to="{ name: 'agent-buy-lottery' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>ซื้อหวยเอเย่นต์</span>
        </b-list-group-item>

        <b-list-group-item
          v-if="role === 'agent'"
          class="bg-transparent text-white"
          :to="{ name: 'cart-agent' }"
        >
          <feather-icon
            size="16"
            icon="ShoppingCartIcon"
            class="mr-50"
          />
          <span>คำสั่งซื้อ</span>
        </b-list-group-item>

        <b-list-group-item
          v-else
          class="bg-transparent text-white"
          :to="{ name: 'cart' }"
        >
          <feather-icon
            size="16"
            icon="ShoppingCartIcon"
            class="mr-50"
          />
          <span>คำสั่งซื้อ</span>
        </b-list-group-item>

        <b-list-group-item
          v-if="role === 'agent'"
          class="bg-transparent text-white"
          :to="{ name: 'lotto-list-agent' }"
        >
          <feather-icon
            size="16"
            icon="ShoppingBagIcon"
            class="mr-50"
          />
          <span>รายการสลากฯ</span>
        </b-list-group-item>

        <b-list-group-item
          v-else
          class="bg-transparent text-white"
          :to="{ name: 'history' }"
        >
          <feather-icon
            size="16"
            icon="ShoppingBagIcon"
            class="mr-50"
          />
          <span>รายการสลากฯ</span>
        </b-list-group-item>

        <b-list-group-item
          class="bg-transparent text-white"
          :to="{ name: 'home' }"
        >
          <feather-icon
            size="16"
            icon="BookmarkIcon"
            class="mr-50"
          />
          <span>ผลรางวัล</span>
        </b-list-group-item>

        <!-- <b-list-group-item
          class="bg-transparent text-white"
          :to="{ name: 'profile' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>ข้อมูลของฉัน</span>
        </b-list-group-item> -->
      </b-list-group>
      <!-- <template #footer>
        <div class="p-1 bg-warning text-center">
          <span
            class="text-white"
            @click="logout()"
          ><i class="fas fa-sign-out-alt" /> ออกจากระบบ</span>
        </div>
      </template> -->
    </b-sidebar>
    <!-- end sidebar -->
  </div>
</template>

<script>
import {
  // BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  // BDropdownDivider,
  BAvatar,
  BNav,
  BNavItem,
  BModal,
  BButton,
  BFormInput,
  BRow,
  BCol,
  BSidebar, VBToggle, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import SidebarContent from './SidebarContent.vue'
// import SidebarContentFooter from './SidebarContentFooter.vue'
// import { initialAbility } from '@/libs/acl/config'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
    BNav,
    BNavItem,
    BModal,
    BButton,
    BFormInput,
    BRow,
    BCol,
    BSidebar,
    BListGroup,
    BListGroupItem,
    // Navbar Components
    // DarkToggler,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      banks: '',
      optionBank: [
        { title: 'Square' },
        { title: 'Kbank' },
        { title: 'SCB' },
        { title: 'KTB' },
        { title: 'TMB' },
        { title: 'BBL' },
        { title: 'GSB' },
        { title: 'CIMB' },
        { title: 'TISCO' },
        { title: 'BAY' },
        { title: 'KTB' },
        { title: 'TMB' },
        { title: 'BBL' },
        { title: 'GSB' },
        { title: 'CIMB' },
        { title: 'TISCO' },
        { title: 'BAY' },
        { title: 'KTB' },
        { title: 'TMB' },
        { title: 'BBL' },
        { title: 'GSB' },
        { title: 'CIMB' },
        { title: 'TISCO' },
        { title: 'BAY' },
      ],
      required,
      code: '',
      actived: 'home',
      email: '',
      password: '',
      loggedIn: this.$auth.loggedIn,
      ag: 1,
      ref: null,
      match: null,
      next: null,
      getOTP: false,
      confirmPassword: false,
      lineID: '',
      otp: '',
      knowby: null,
      promo: '',
      wallet: '',
      promotion: '',
      surname: '',
      name: '',
      s_bank: null,
      accno: '',
      selected: null,
      invite_friend: '',
      fname: '',
      lname: '',
      trueaccno: '',
      // bank: '',
      UserData: JSON.parse(localStorage.getItem('userData')),
      role: null,
      tel: '',
      balance: JSON.parse(localStorage.getItem('balance')),
    }
  },
  created() {
    if (localStorage.getItem('userData')) {
      this.UserData = JSON.parse(localStorage.getItem('userData'))
      this.role = this.UserData.role
      // console.log(this.role)
    }
  },
  mounted() {
    if (localStorage.getItem('userData')) {
      this.UserData = JSON.parse(localStorage.getItem('userData'))
      this.role = this.UserData.role
      // console.log(this.role)
      this.getBalance()
    }
  },
  methods: {
    getBalance() {
      const Obj = {
        UserToken: this.UserData.UserToken,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/wallet/show', Obj)
        .then(ress => {
          // console.log(ress.data.WalletData.balance)
          this.balance = ress.data.WalletData.balance
          localStorage.setItem('balance', JSON.stringify(ress.data.WalletData.balance))
        })
    },
    getOTPs() {
      const formData = {
        tels: this.tel,
        agent_id: this.ag,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/otp/store', formData)
        .then(response => {
          this.ref = response.data.ref
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    modalRegister() {
      this.$router.push('/register')
    },
    modalLogin() {
      this.$refs['modal-login'].show()
    },
    hideModal() {
      this.$refs['modal-login'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['modal-login'].toggle('#toggle-btn')
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.login()
        }
      })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    login() {
      // this.$refs.loginForm.validate().then(success => {
      // if (success) {
      // console.log(this.email)
      useJwt
        .login({
          email: this.email,
          password: this.password,
          agent_id: 1,
        })
        .then(response => {
          const userData = response.data
          useJwt.setToken(response.data.token)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          // console.log(userData)
          this.role = userData.role
          this.email = ''
          this.password = ''
          this.hideModal()
          this.getBalance()
          // this.$ability.update(userData.ability)

          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar
          // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          // this.$router
          //   .push(getHomeRouteForLoggedInUser(userData.role))
          //   .then(() => {
          //     this.$toast({
          //       component: ToastificationContent,
          //       position: 'top-right',
          //       props: {
          //         title: 'ยินดีต้อนรับ',
          //         icon: 'CoffeeIcon',
          //         variant: 'success',
          //         text: 'คุณเข้าสู่ระบบสำเร็จแล้ว',
          //       },
          //     })
          //   })
          //   .catch(error => {
          //     console.log(error)
          //   })
          if (this.role === 'admin') {
            window.location.href = '/'
          } else if (this.role === 'user') {
            window.location.href = '/buy-lottery'
          } else if (this.role === 'agent') {
            window.location.href = '/agent/buy-lottery'
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ERROR User or Password incorrect',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
        // }
      // })
    },
    logout() {
      console.log('logout')
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      // this.$ability.update(initialAbility)
      this.role = null
      // Redirect to login page
      // this.$router.replace('/')
      window.location.replace('https://sabaideelotto.com')
    },
  },
}
</script>

<style scoped>
.active {
  background-color: transparent !important;
  border-bottom-style: solid;
  border-width: 4px;
  border-image: linear-gradient(
      160deg,
      #b47e11,
      #fef1a2,
      #a54e07,
      #b47e11,
      #fef1a2,
      #bc881b,
      #a54e07
    )
    1 !important;
  font-weight: 500;
}
label {
  font-size: 1.2rem;
}
.form-control {
  font-size: 1.2rem;
  border-radius: 10px;
  border-bottom-style: solid;
  border-width: 2px;
  border-image: linear-gradient(
      180deg,
      #b47e11,
      #fef1a2,
      #a54e07,
      #b47e11,
      #fef1a2,
      #bc881b,
      #a54e07
    )
    1 !important;
}
.btn-facebook {
  background-color: #3b5998 !important;
  color: #fff;
  transform: scale(1);
  animation: pulse-blue 2s infinite;
}

.btn-line {
  background-color: #00b900 !important;
  color: #fff;
  transform: scale(1);
  animation: pulse-green 2s infinite;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
