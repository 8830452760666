<template>
  <div class="menu-footer z-10 justify-center">
    <span
      v-if="role"
      class="balances"
    >ยอดเงิน {{ balance ? Commas(balance) : 0.00 }} บาท</span>
    <div class="w-28 menu-back">
      <div class="d-flex align-items-center justify-content-center p-1">
        <router-link
          v-if="role === 'agent'"
          class="items-1 text-center mr-1 ml-1"
          :class="{actives: $route.name === 'agent-buy-lottery'}"
          :to="{ name: 'agent-buy-lottery' }"
        >

          <i class="fas fa-store" /><br>
          <small>หน้าร้าน</small>
        </router-link>
        <!-- <router-link
          v-else
          class="items-1 text-center mr-1 ml-1"
          :class="{actives: $route.name === 'home'}"
          :to="{ name: 'home' }"
        >

          <i class="fas fa-store" /><br>
          <small>ผลรางวัล</small>
        </router-link> -->

        <router-link
          v-if="role !== 'agent'"
          class="items-1 text-center mr-1 ml-1 d-lg-none d-md-none"
          :class="{actives: $route.name === 'buy-lottery'}"
          :to="{ name: 'buy-lottery' }"
        >

          <i class="fas fa-store" /><br>
          <small>ซื้อหวย</small>
        </router-link>

        <router-link
          v-if="UserData && role === 'agent'"
          class="items-1 text-center mr-1 ml-1"
          :class="{actives: $route.name === 'lotto-list-agent'}"
          :to="{ name: 'lotto-list-agent' }"
        >
          <i class="fas fa-money-check" /><br>
          <small>สลากของฉัน</small>
        </router-link>

        <router-link
          v-if="UserData && role !== 'agent'"
          class="items-1 text-center mr-1 ml-1"
          :class="{actives: $route.name === 'lotto-list'}"
          :to="{ name: 'lotto-list' }"
        >
          <i class="fas fa-money-check" /><br>
          <small>สลากของฉัน</small>
        </router-link>
        <!-- <router-link
          v-if="UserData"
          class="items-1 text-center mr-1 ml-1"
          :class="{actives: $route.name === 'withdraw'}"
          :to="{ name: 'withdraw' }"
        >
          <i class="fas fa-hand-holding-usd" /><br>
          <small>ฝากถอน</small>
        </router-link> -->
        <router-link
          v-if="role === 'agent'"
          class="items-1 text-center mr-1 ml-1"
          :class="{actives: $route.name === 'cart-agent'}"
          :to="{ name: 'cart-agent' }"
        >
          <i class="fas fa-shopping-basket" />
          <div
            v-if="lottoset.length > 0"
            class="bage"
          >
            <div>{{ lottoset.length ? lottoset.length : 0 }}</div>
          </div><br>
          <small>ตะกร้า</small>
        </router-link>
        <router-link
          v-else
          class="items-1 text-center mr-1 ml-1"
          :class="{actives: $route.name === 'cart'}"
          :to="{ name: 'cart' }"
        >
          <i class="fas fa-shopping-basket" /><div
            v-if="lotto.length > 0"
            class="bage"
          >
            <div>{{ lotto.length ? lotto.length : 0 }}</div>
          </div><br>
          <small>ตะกร้า</small>
        </router-link>

        <!-- <a
          class="items-1 text-center mr-1 ml-1"
          href="https://line.me/R/ti/p/@123tlwop"
        >
          <i
            class="fab fa-line"
          /><br>
          <small>ติดต่อ</small>
        </a> -->
      </div>
    </div>

    <!-- <div class="contact-line">
      <a
        href="https://line.me/R/ti/p/@123tlwop"
      >
        <img
          src="/img/line.png"
          alt="line"
          height="70px"
        >
      </a>
    </div> -->
  </div>
</template>

<script>
// import { BCard, BPaginationNav } from 'bootstrap-vue'

export default {
  name: 'Order',
  components: {
    // BCard,
    // BPaginationNav,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      selected: 'center',
      number: 1,
      selectNum: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
      ],
      lotto: [],
      round: null,
      balance: 0,
      lottoset: [],
      role: JSON.parse(localStorage.getItem('userData')).role,
    }
  },
  created() {
    this.interval = setInterval(() => {
      if (localStorage.getItem('userData')) {
        this.role = JSON.parse(localStorage.getItem('userData')).role
        this.getLotto()
        this.getLottoSet()
        this.getBalance()
      }
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    if (localStorage.getItem('userData')) {
      this.role = JSON.parse(localStorage.getItem('userData')).role
      this.getLotto()
      this.getLottoSet()
      this.getBalance()
    }
  },
  methods: {
    getround() {
      this.$http
        .get('https://api.sabaideelotto.com/api/seamless/lottery/getrounddetail')
        .then(ress => {
          // console.log(ress.data)
          this.round = ress.data
        })
    },
    async getLotto() {
      const obj = {
        UserToken: this.UserData.UserToken,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/lottery/getcartbyuser', obj)
        .then(ress => {
          // console.log(ress.data)
          this.lotto = ress.data
        })
    },
    async getLottoSet() {
      // localStorage.removeItem('lottoset')
      this.$http
        .get('https://api.sabaideelotto.com/api/seamless/lottery/getsetcartbyuser')
        .then(ress => {
          // console.log(ress.data)
          this.lottoset = ress.data
          // localStorage.setItem('lottoset', JSON.stringify(this.lottoset))
        })
    },
    getBalance() {
      const Obj = {
        UserToken: this.UserData.UserToken,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/wallet/show', Obj)
        .then(ress => {
          // console.log(ress.data.WalletData.balance)
          this.balance = ress.data.WalletData.balance
          localStorage.setItem('balance', JSON.stringify(ress.data.WalletData.balance))
        })
    },
    buyLotto() {
      if (!this.lotto) {
        this.SwalError('กรุณาเลือก สลาก ที่ต้องการซื้อ')
      } else {
        this.$http
          .post('https://api.sabaideelotto.com/api/seamless/lottery/buylotto', {
            lotto: this.lotto,
          })
          .then(() => {
            this.Success('สั่งซื้อสำเร็จ')
            this.$router.push({ name: 'lotto-list' })
          }).catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ซื้อสลากไม่สำเร็จ!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },

}

</script>

<style>
.justify-center {
    justify-content: center;
}
.menu-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 999;

}
.z-10 {
    z-index: 10;
}
.w-28 {
    width: 28rem;
}
.menu-back {
    background-color: rgba(255, 255, 255, 0.932);
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.527);
}
.items-1 {
    font-weight: 600;
    font-size: 14px;
}
.z-10 {
  /* border-radius: 15px;
    box-shadow:  0px 0px 10px 5px rgba(0, 0, 0, 0.5); */
    z-index: 10;
    position: fixed;
    bottom: 1rem;
    right: 0;
    /* overflow: visible; */
    height: 60px;
}
a {
    color: rgb(87, 87, 87);
}
.actives {
    color: #01B295;
}
.bage {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #01B295;
    color: #fff;
    font-size: 12px;
    margin-left: 27px;
    margin-top: -27px;
    border-radius: 50%;
}
.bage div {
  text-align: center;
   margin-top: -3px;
  /* position: absolute;

  margin-left: -5px; */
    font-size: 8px;
}
.balances {
    position: absolute;
    width: auto;
    height: 22px;
    background-color: #01B295;
    color: #fff;
    font-size: 12px;
    /* margin-left: 27px; */
    margin-top: -72px;
    border-radius: 12px;
    padding: 0 15px;
    border: 2px solid #fff;
}

</style>
